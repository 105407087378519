import safeParseURL from "./safeParseURL"

/**
 * Some redirects cannot be handled by netlify.toml,
 * so we need to do it in the client
 */
export default function runRedirects() {
  const href = window.location.href.toLowerCase()
  const url = safeParseURL(window.location.href.toLowerCase())
  if (!url) return

  // if we are on "https://blog.boostplatform.io/" then redirect to "www.boostinsurance.com/blog"
  if (url.hostname === "blog.boostplatform.io") {
    window.location.replace(
      href.replace("blog.boostplatform.io", "boostinsurance.com/blog")
    )
  }
  // if we are on "https://boostplatform.io/" then redirect to "www.boostinsurance.com"
  else if (url.hostname === "boostplatform.io") {
    window.location.replace(
      href.replace("boostplatform.io", "boostinsurance.com")
    )
  }
  // if we are on "/api-docs" then redirect to links.apiDocs
  else if (href.includes("/api-docs")) {
    window.location.replace("https://docs.boostinsurance.io/public-docs/")
  }
}
