import ApiDocs from "images/global/ApiDocs.svg"
import blogNavSVG from "images/global/blogNav.svg"
import caseStudiesNavSVG from "images/global/caseStudiesNav.svg"
import DevOverview from "images/global/DevOverview.svg"
import mediaNavSVG from "images/global/mediaNav.svg"
import PlatformOverview from "images/global/PlatformNav.svg"
import HeaderSectionType from "types/HeaderSectionType"
import links from "utils/links"

const headerData: HeaderSectionType[][] = [
  [
    {
      name: "Platform",
      gradient: "greenBlueHorizontal",
      color: "green500",
      subSections: [
        {
          style: "single",
          links: [
            {
              style: "large",
              slug: "/platform",
              name: "Overview",
              navigationDescription:
                "Everything you need to launch, manage and grow your insurance program",
              icon: PlatformOverview,
            },
          ],
        },
      ],
    },
    {
      gradient: "greenBlueHorizontal",
      color: "green500",
      route: "/platform",
      subSections: [
        {
          links: [
            {
              slug: "/developer",
              name: "Developer Overview",
              navigationDescription:
                "Get up and running quickly with Boost’s modern infrastructure and intuitive API",
              icon: DevOverview,
            },
            {
              slug: links.apiDocs,
              name: "API Documentation",
              navigationDescription:
                "Get the latest information on Boost’s API, with real-time updates to documentation",
              icon: ApiDocs,
            },
            {
              slug: "/developer",
              name: "Developer Overview",
              navigationDescription:
                "Get up and running quickly with Boost’s modern infrastructure and intuitive API",
              icon: DevOverview,
            },
            {
              slug: links.apiDocs,
              name: "API Documentation",
              navigationDescription:
                "Get the latest information on Boost’s API, with real-time updates to documentation",
              icon: ApiDocs,
            },
            {
              slug: "/developer",
              name: "Developer Overview",
              navigationDescription:
                "Get up and running quickly with Boost’s modern infrastructure and intuitive API",
              icon: DevOverview,
            },
            {
              slug: links.apiDocs,
              name: "API Documentation",
              navigationDescription:
                "Get the latest information on Boost’s API, with real-time updates to documentation",
              icon: ApiDocs,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      name: "Insurance Products",
      color: "green500",
      route: "/product",
      subSections: [
        {
          name: "Commercial Lines",
          links: [], // generated from contentful
        },
        {
          name: "Personal Lines",
          links: [], // generated from contentful
        },
        {
          name: "Other",
          links: [], // generated from contentful
        },
      ],
    },
  ],
  [
    {
      name: "Solutions",
      color: "blue500",
      route: "/solution",
      subSections: [
        {
          links: [], // generated from contentful
        },
      ],
    },
  ],
  [
    {
      name: "Developers",
      color: "yellow500",
      subSections: [
        {
          style: "single",
          links: [
            {
              slug: "/developer",
              name: "Developer Overview",
              navigationDescription:
                "Get up and running quickly with Boost’s modern infrastructure and intuitive API",
              icon: DevOverview,
            },
            {
              slug: links.apiDocs,
              name: "API Documentation",
              navigationDescription:
                "Get the latest information on Boost’s API, with real-time updates to documentation",
              icon: ApiDocs,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      name: "Resources",
      color: "light500",
      subSections: [
        {
          style: "single",
          links: [
            {
              slug: "/blog",
              icon: blogNavSVG,
              name: "Blog",
              navigationDescription:
                "Get news, tips, and best practices for insurance and technology",
            },
            {
              slug: "/media",
              icon: mediaNavSVG,
              name: "Media",
              navigationDescription:
                "Follow the latest coverage of Boost and the changing insurance industry",
            },
            {
              slug: "/case-studies",
              icon: caseStudiesNavSVG,
              name: "Case Studies",
              navigationDescription:
                "Discover how Boost is powering modern insurance innovators",
            },
          ],
        },
      ],
    },
  ],
]

export default headerData
