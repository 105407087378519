const gradients = {
  primaryVertical:
    "linear-gradient(193.56deg, #FCE344 0.85%, #79FA4B 55.84%, #30D7F1 107.2%)",
  primaryHorizontal:
    "linear-gradient(277.33deg, #FCE344 -2.48%, #79FA4B 47.21%, #30D7F1 93.62%)",
  primaryDiagonal:
    "linear-gradient(330.03deg, #FCE344 1.83%, #79FA4B 52.59%, #30D7F1 100%)",
  blueGreenVertical: "linear-gradient(14.09deg, #79FA4B 8.63%, #30D7F1 94.36%)",
  blueGreenHorizontal:
    "linear-gradient(100.7deg, #79FA4B 8.74%, #30D7F1 92.06%)",
  greenBlueHorizontal:
    "linear-gradient(100.7deg, #30D7F1 8.74%, #79FA4B 92.06%)",
  yellowGreenHorizontal:
    "linear-gradient(283.56deg, #79FA4B 9.72%, #FCE344 99.15%)",
  yellowGreenVertical:
    "linear-gradient(193.56deg, #79FA4B 9.72%, #FCE344 94.31%)",
  greenVertical:
    "linear-gradient(193.56deg, #79FA4B 9.72%, rgba(164, 255, 132, 0) 94.31%)",
  greenHorizontal:
    "linear-gradient(270.83deg, #79FA4B -11.78%, rgba(164, 255, 132, 0) 110.04%)",
  secondaryDark: "linear-gradient(193.56deg, #4B5E61 9.72%, #002025 94.31%)",
  secondaryDarkBlue:
    "linear-gradient(270.83deg, #00AEC9 -11.78%, #32494D 110.04%)",
}

const colors = {
  pureBlack: "#000",
  pureWhite: "#fff",
  light800: "#78786D",
  light700: "#8F8F83",
  light600: "#A5A497",
  light500: "#B9B8AB",
  light400: "#CDCCBB",
  light300: "#ECEAD9",
  /**
   * same as light300
   */
  lightGray: "#ECEAD9",
  light200: "#F5F4EB",
  light100: "#FFFFFA",
  /**
   * same as light100
   */
  mainWhite: "#FFFFFA",
  miscRed: "#F8776E",
  dark800: "#002025",
  /**
   * same as dark800
   */
  darkTeal: "#002025",
  dark700: "#05333A",
  dark600: "#244348",
  /**
   * same as dark600
   */
  darkGray: "#244348",
  /**
   * same as dark600
   */
  darkGrey: "#244348",
  dark500: "#455C60",
  dark400: "#54696C",
  dark300: "#7D8F92",
  dark200: "#9EAEB0",
  dark100: "#B7C6C9",
  green800: "#367B17",
  green700: "#4BB31D",
  green600: "#63EC33",
  green500: "#79FA4B",
  green400: "#A4ED8A",
  green300: "#C0EFAF",
  green200: "#CCFBBC",
  blue800: "#0C616F",
  blue700: "#188C9E",
  blue600: "#38B9CD",
  blue500: "#34D0E8",
  blue400: "#7EE2F1",
  blue300: "#AFEDF6",
  blue200: "#D2F7FD",
  yellow800: "#AE990C",
  yellow700: "#D3B90F",
  yellow600: "#E1CB3D",
  yellow500: "#FCE449",
  yellow400: "#F9E985",
  yellow300: "#F4EAA5",
  yellow200: "#FCF6D1",
  gradients,
} as const

export type ColorType = Exclude<keyof typeof colors, "gradients">
export type GradientType = keyof typeof gradients

export default colors
