import React, { useRef } from "react"

import styled, { css } from "styled-components"

import { ReactComponent as HeaderLogo } from "images/global/HeaderLogo.svg"
import colors from "styles/colors"
import media from "styles/media"
import UniversalLink from "utils/Loader/UniversalLink"
import useAdaptiveColor from "utils/useAdaptiveColor"

interface LogoProps {
  showText: boolean
}

export default function Logo({ showText }: LogoProps) {
  const logo = useRef<SVGSVGElement>(null)

  useAdaptiveColor(
    () => logo.current,
    {
      fill: colors.dark800,
    },
    {
      fill: colors.light100,
    }
  )

  return (
    <StyledHeaderLogo showText={showText} to="/">
      <HeaderLogo ref={logo} aria-label="Boost Insurance Home" />
    </StyledHeaderLogo>
  )
}

const StyledHeaderLogo = styled(UniversalLink)<{ showText: boolean }>`
  cursor: pointer;
  fill: ${colors.blue800};
  ${({ showText: showLogoText }) =>
    !showLogoText &&
    css`
      :not(:focus) {
        svg {
          fill: transparent !important;
        }
      }
    `}
  svg {
    transition: fill 0.2s ease-in-out;
  }

  width: 152px;
  margin-right: 57px;
  ${media.desktop} {
    width: 10.556vw;
    margin-right: 3.958vw;
  }
  ${media.tablet} {
    margin-right: 0;
  }
  ${media.mobile} {
    margin-right: 0;
  }
`
