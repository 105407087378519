import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import colors, { ColorType, GradientType } from "styles/colors"
import media from "styles/media"
import textStyles from "styles/text"
import type { Link, ContentfulLink } from "types/HeaderSectionType"
import UniversalLink from "utils/Loader/UniversalLink"

interface SectionItemsProps {
  sectionLinks: readonly (Link | ContentfulLink | null)[] | null | undefined
  color: ColorType | undefined
  gradient: GradientType | undefined
  sectionRoute?: string
}

export default function SectionItems({
  sectionRoute = undefined,
  sectionLinks,
  color,
  gradient,
}: SectionItemsProps) {
  const links = sectionLinks?.map(link => {
    const description = link?.navigationDescription
    const image =
      typeof link?.icon === "string" ? (
        <img src={link.icon || ""} alt={link.name || "nav icon"} />
      ) : (
        link?.icon?.gatsbyImageData && (
          <GatsbyImage
            className="gatsby-image"
            image={link.icon.gatsbyImageData}
            alt={link.icon.description || link.name || "nav icon"}
          />
        )
      )

    const route = sectionRoute
      ? `${sectionRoute}/${link?.slug || ""}`
      : link?.slug || "/404"

    if (link?.style === "large") {
      return (
        <BigItem to={route} key={link.slug}>
          {!!image && image}
          <LinkTitle textColor={color} gradient={gradient}>
            {link.name}
          </LinkTitle>
          <LinkDescription>{description}</LinkDescription>
        </BigItem>
      )
    }
    return (
      <SmallWrapper key={link?.slug}>
        <SmallItem to={route}>
          {!!image && image}
          <LinkTitle textColor={color} gradient={gradient}>
            {link?.shortName ?? link?.name}
          </LinkTitle>
        </SmallItem>
      </SmallWrapper>
    )
  })

  return <>{links}</>
}

const BigItem = styled(UniversalLink)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-column: 1 / -1;
  align-items: center;
  height: min-content;

  .gatsby-image,
  img {
    grid-row: 1 / 3;
    ${media.tablet} {
      width: 5.859vw;
      height: 5.859vw;
    }
    ${media.mobile} {
      width: 13.333vw;
      height: 13.333vw;
    }
  }

  ${media.tablet} {
    gap: 0 1.465vw;
    padding: 1.758vw 0.488vw;
  }
  ${media.mobile} {
    gap: 0 2.667vw;
    padding: 2.267vw 0vw;
  }
`

const LinkTitle = styled.div<{
  textColor: ColorType | undefined
  gradient: GradientType | undefined
}>`
  ${textStyles.h8}

  ${({ textColor, gradient }) => {
    if (gradient) {
      return `
        background: ${colors.gradients[gradient]};
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        background-size: 100%;
        background-clip: text;
      `
    }
    if (textColor) {
      return `
        color: ${colors[textColor]};
      `
    }
  }}

  flex-shrink: 9999; // prevents text from causing icon to shrink

  ${media.mobile} {
    ${textStyles.h10}
  }
`

const LinkDescription = styled.div`
  ${textStyles.bodyDefault}
  color: ${colors.dark300};

  ${media.tablet} {
    width: 29.3vw;
  }

  ${media.mobile} {
    width: 51vw;
    ${textStyles.bodySmall}
  }
`

const SmallWrapper = styled.div`
  ${media.tablet} {
    padding: 1.758vw 1.953vw 1.953vw 0.488vw;
  }
  ${media.mobile} {
    padding: 3.2vw 0;
  }
`

const SmallItem = styled(UniversalLink)`
  display: flex;
  align-items: center;

  ${media.tablet} {
    gap: 1.465vw;
  }
  ${media.mobile} {
    gap: 2.67vw;
  }

  .gatsby-image,
  img {
    ${media.tablet} {
      width: 3.906vw;
      height: 3.906vw;
    }
    ${media.mobile} {
      width: 8vw;
      height: 8vw;
    }
  }
`
