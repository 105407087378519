import React from "react"

import styled, { css } from "styled-components"

import colors from "styles/colors"
import textStyles from "styles/text"
import fullyResponsive from "utils/fullyResponsive"

interface SecondaryButtonProps {
  children: React.ReactNode
  className?: string
  onClick: React.MouseEventHandler
  label: string
}

export default function SocialIcon({
  children,
  className = "",
  onClick = () => false,
  label,
}: SecondaryButtonProps) {
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  return (
    <Button
      ref={buttonRef}
      className={className}
      onClick={onClick}
      aria-label={label}
    >
      {children}
    </Button>
  )
}

const Button = styled.button`
  color: ${colors.light100};
  position: relative;
  z-index: 1;
  border: none;
  background: none;
  ${textStyles.h9};
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  ${fullyResponsive(css`
    width: 36px;
    height: 36px;
  `)}
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: ${colors.gradients.primaryHorizontal};
    background-size: 110%;
    background-repeat: no-repeat;
    border-radius: 99px;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border-radius: 99px;
    border: 1px solid transparent;
    background: ${colors.gradients.primaryHorizontal};
    background-size: 120% 120%;
    background-repeat: no-repeat;
    background-position: center;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    mask-composite: exclude;
  }

  :hover {
    color: ${colors.dark800} !important;
    :before {
      opacity: 1;
    }
  }
`
