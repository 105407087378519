import React, { useEffect, useRef, useState } from "react"

import gsap from "gsap"
import ScrollSmoother from "gsap/ScrollSmoother"
import styled from "styled-components"

import MenuButton from "components/Buttons/MenuButton"
import PrimaryButton from "components/Buttons/Primary"
import colors from "styles/colors"
import media from "styles/media"
import HeaderSectionType from "types/HeaderSectionType"
import loader from "utils/Loader"
import useAdaptiveColor from "utils/useAdaptiveColor"

import HeaderSection from "./HeaderSection"

interface Props {
  data: HeaderSectionType[][]
}

export default function ResponsiveMenu({ data }: Props) {
  const state = useState(false)
  const [menuIsOpen, setMenuIsOpen] = state
  const menu = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // pause or unpause the smoother
    ScrollSmoother.get()?.paused(menuIsOpen)

    gsap.set(menu.current, { visibility: "visible" })
    if (menu.current) {
      gsap.to(menu.current, {
        width: menuIsOpen ? "auto" : "60",
        height: menuIsOpen ? window.innerHeight - 20 : "0",
        onComplete: () => {
          if (!menuIsOpen) {
            gsap.set(menu.current, { visibility: "hidden" })
          }
        },
        duration: 0.35,
        ease: menuIsOpen ? "power2.out" : "power2.in",
      })
    }

    if (menuIsOpen) {
      const updateHeight = () => {
        gsap.set(menu.current, { height: window.innerHeight - 20 })
      }
      window.addEventListener("resize", updateHeight)
      return () => {
        window.removeEventListener("resize", updateHeight)
        ScrollSmoother.get()?.paused(false)
      }
    }
    return () => {
      ScrollSmoother.get()?.paused(false)
    }
  }, [menuIsOpen])

  const sections = data.map(sectionList => (
    <HeaderSection key={sectionList[0].name} sectionList={sectionList} />
  ))

  useAdaptiveColor(
    () => menu.current,
    {
      boxShadow: `2px 8px 40px rgba(0, 0, 0, 0.00)`,
    },
    {
      boxShadow: `0px 10px 80px rgba(0, 0, 0, 0.15)`,
    }
  )

  useEffect(() => {
    // close menu on navigation
    const closeMenu = () => {
      setMenuIsOpen(false)
    }
    loader.addEventListener("anyEnd", closeMenu)
    return () => loader.removeEventListener("anyEnd", closeMenu)
  }, [setMenuIsOpen])

  return (
    <>
      <MenuButton
        data-flip-id="menuButton"
        className="menuButton"
        state={state}
      />
      <Menu ref={menu}>
        <FixedWidth>
          <ButtonWrapper>
            <TopRow to="/contact-us">Contact Us</TopRow>
          </ButtonWrapper>
          {sections}
        </FixedWidth>
      </Menu>
    </>
  )
}

const Menu = styled.div`
  position: fixed;
  background: ${colors.dark800};
  z-index: 2;
  overflow-y: auto;
  overscroll-behavior: none;
  height: 60px;

  ${media.tablet} {
    top: -1.66vw;
    right: 0.977vw;
    width: 55.664vw;
    border-radius: 4.883vw;
  }
  ${media.mobile} {
    top: -2.667vw;
    right: 3.333vw;
    width: calc(100vw - 6.667vw);
    border-radius: 8vw;
  }
`

const FixedWidth = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tablet} {
    width: 55.664vw;
  }
  ${media.mobile} {
    width: calc(100vw - 6.667vw);
  }
`

const ButtonWrapper = styled.div`
  position: sticky;
  background-color: ${colors.darkTeal};
  z-index: 9;
  border-bottom: 1px dashed ${colors.dark600};

  ${media.tablet} {
    top: 0;
  }

  ${media.mobile} {
    width: 100%;
    padding: 2.667vw;
    top: 0;
  }
`

const TopRow = styled(PrimaryButton)`
  ${media.tablet} {
    width: 43.945vw;
    height: 5.859vw;
    margin: 1.953vw;
  }
  ${media.mobile} {
    width: 74.933vw;
    height: 10.667vw;
    margin: 0;
  }
`
