const links = {
  temporaryLink: "https://www.google.com",
  twitter: "https://twitter.com/boostinsurance",
  linkedin: "https://www.linkedin.com/company/boostinsurance/",
  medium: "https://medium.com/@boostinsurance",

  apiDocs: "https://learn.boostinsurance.com/",
  getStarted: "https://launchpad.boostinsurance.com/",
}

export default links
