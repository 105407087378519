import React from "react"

const footerLegal = (
  <div>
    Boost Insurance Agency, Inc. (“Boost”) is a licensed Property &amp; Casualty
    (“P&amp;C”) insurance producer in the states where it transacts insurance.
    <br /> <br />
    P&amp;C insurance coverage, on admitted or non-admitted basis, is available
    only to insureds in those states where Boost is licensed to transact
    insurance as a producer and holds a valid company appointment. All coverages
    are subject to policy terms, conditions and exclusions. Boost may act as a
    general agent for one or more insurance carriers. In some circumstances,
    some insurers with whom Boost may produce business may not be licensed by,
    or subject to the supervision of, the states where they transact insurance.
    An insurance policy issued by any such non-admitted insurer is not subject
    to many of the rate and policy form regulations.
  </div>
)

export default footerLegal
