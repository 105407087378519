import React, { MouseEventHandler } from "react"

import { Link } from "gatsby"
import gsap from "gsap"
import styled, { css } from "styled-components"

import colors from "styles/colors"
import textStyles from "styles/text"
import fullyResponsive from "utils/fullyResponsive"
import UniversalLink from "utils/Loader/UniversalLink"
import useAdaptiveColor from "utils/useAdaptiveColor"

import { Arrow } from "./Primary"

interface SecondaryButtonProps {
  variant?: "small" | "large" | "smallArrow" | "largeArrow"
  children: React.ReactNode
  className?: string
}

interface PropsWithOnClick extends SecondaryButtonProps {
  onClick: MouseEventHandler
  to?: never
  type?: never
}

interface PropsWithTo extends SecondaryButtonProps {
  to: string
  onClick?: never
  type?: never
}

interface PropsWithType extends SecondaryButtonProps {
  type: "submit"
  onClick?: MouseEventHandler
  to?: never
}

export default function SecondaryButton({
  children,
  className = "",
  variant = "large",
  onClick = undefined,
  to = "",
  type = undefined,
}: PropsWithOnClick | PropsWithTo | PropsWithType) {
  const buttonRef = React.useRef<
    HTMLButtonElement & HTMLAnchorElement & Link<unknown>
  >(null)
  const arrow = variant.includes("Arrow")

  useAdaptiveColor(
    () => buttonRef.current,
    {
      color: colors.dark800,
      fill: colors.dark800,
    },
    {
      color: colors.light100,
      fill: colors.light100,
    }
  )

  const mouseIn = () => {
    gsap.to(buttonRef.current, {
      "--progress": "100%",
      ease: "power3.inOut",
    })
  }

  const mouseOut = () => {
    gsap.to(buttonRef.current, {
      "--progress": "0%",
      ease: "power3.inOut",
    })
  }

  return (
    <Button
      to={to}
      forwardRef={buttonRef}
      className={className}
      $large={variant.includes("large")}
      onMouseEnter={mouseIn}
      onMouseLeave={mouseOut}
      onClick={onClick}
      type={type}
    >
      <div>{children}</div>
      {arrow && <LocalArrow $large={variant.includes("large")} />}
    </Button>
  )
}

const LocalArrow = styled(Arrow)`
  fill: inherit;
`

const Button = styled(UniversalLink)<{ $large: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 1;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  width: 100%;
  --progress: 0%;

  ${({ $large }) => ($large ? textStyles.h8 : textStyles.h9)}
  ${({ $large }) =>
    fullyResponsive(
      $large
        ? css`
            padding: 18px 30px;
            gap: 15px;
          `
        : css`
            padding: 9px 20px;
            gap: 10px;
          `
    )}

  /* wrapper around content, including underline */
  > div {
    position: relative;
    width: max-content;
    :before {
      width: var(--progress);
      content: "";
      position: absolute;
      bottom: -2px;
      height: 1px;

      background: ${colors.gradients.primaryHorizontal};
      background-size: 105%;
      border-radius: 0px;
      z-index: -1;
    }
  }

  /* styles for border */
  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border-radius: 99px;
    border: 1px solid transparent;
    background: ${colors.gradients.primaryHorizontal};
    background-size: 105%;
    background-position: center;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    mask-composite: exclude;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    clip-path: polygon(
      100% 50%,
      100% 0%,
      calc(2 * var(--progress)) 0%,
      calc(2 * var(--progress)) 50%,
      100% 50%,
      0% 50%,
      0% 100%,
      calc(200% - calc(2 * var(--progress))) 100%,
      calc(200% - calc(2 * var(--progress))) 50%
    );
    z-index: -1;
  }

  :hover {
    ${LocalArrow} {
      transform: translateX(5px);
    }
  }
`
