import React, { useContext } from "react"

import styled, { css } from "styled-components"

import PrimaryButton from "components/Buttons/Primary"
import SecondaryButton from "components/Buttons/Secondary"
import SocialIcon from "components/Buttons/SocialIcon"
import { ScreenContext } from "components/Providers/Screen"
import { ReactComponent as FooterLogo } from "images/global/FooterLogo.svg"
import { ReactComponent as LinkedinSVG } from "images/global/linkedin.svg"
import { ReactComponent as TwitterSVG } from "images/global/twitter.svg"
import colors from "styles/colors"
import media from "styles/media"
import textStyles from "styles/text"
import { fresponsive, ftablet } from "utils/fullyResponsive"
import links from "utils/links"
import UniversalLink from "utils/Loader/UniversalLink"

import footerLegal from "./FooterLegal"
import FooterLinks from "./FooterLinks"

export default function Footer() {
  const { mobile, tablet } = useContext(ScreenContext)
  const currentYear = new Date().getFullYear()

  const buttonVariant = mobile || tablet ? "large" : "small"

  return (
    <Wrapper>
      <Contact>
        <PrimaryButtonStyled
          className="Get_started-footer"
          variant={buttonVariant}
          to={links.getStarted}
        >
          Get Started
        </PrimaryButtonStyled>
        <AlwaysLightSecondary variant={buttonVariant} to="/contact-us">
          Contact Us
        </AlwaysLightSecondary>
      </Contact>
      <FooterLinks />
      <UniversalLink to="/" ariaLabel="Homepage">
        <StyledLogo />
      </UniversalLink>
      <Addy>
        <div>
          Boost Insurance, 22 W. 21st Street 7th floor, New York, NY, 10010
          <br /> <br />
          All contents &copy; {currentYear} Boost Insurance USA, Inc. All rights
          reserved.
        </div>
      </Addy>
      <Socials>
        <SocialIcon
          label="Boost Twitter"
          onClick={() => window.open(links.twitter, "_blank")}
        >
          <Twitter />
        </SocialIcon>
        <SocialIcon
          label="Boost LinkedIn"
          onClick={() => window.open(links.linkedin, "_blank")}
        >
          <Linkedin />
        </SocialIcon>
      </Socials>
      <Legal>{footerLegal}</Legal>
      <VR />
      <HR />
      <HR2 />
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  background-color: ${colors.dark800};
  overflow-x: hidden;
  display: grid;
  place-items: center;
  align-items: center;
  justify-content: center;
  ${textStyles.bodyLarge}
  position: relative;
  grid-template-areas:
    "logo vr links"
    "contact vr links"
    "address vr links"
    "address vr legal"
    "socials vr legal";
  z-index: 3;
  overflow: hidden;

  ${fresponsive(css`
    border-radius: 30px 30px 0 0;
    padding: 100px 112px;
    gap: 30px 60px;
  `)}

  ${media.tablet} {
    grid-template-columns: 178fr 189fr 450fr;
    grid-template-areas:
      "logo address contact"
      "hr hr hr"
      "links links links"
      "hr2 hr2 hr2"
      "legal legal legal";
    gap: 60px 54px;
    padding: 100px 50px;
  }
  ${media.mobile} {
    grid-template-areas: "contact" "links" "hr" "logo" "address" "socials" "legal";
    grid-template-columns: 1fr;
    padding: 5.333vw 4.267vw;
    gap: 6.667vw;
  }
`

const Contact = styled.div`
  grid-area: contact;
  display: grid;
  width: 80%;

  ${fresponsive(css`
    gap: 15px;
  `)}

  ${media.tablet} {
    gap: 1.953vw;
    width: 100%;
    place-self: start;
  }
  ${media.mobile} {
    gap: 4vw;
    width: 100%;
  }
`

const Social = `
  height: auto;
  path {
    fill: ${colors.pureWhite};
  }
`

const PrimaryButtonStyled = styled(PrimaryButton)`
  width: 100%;
`

const AlwaysLightSecondary = styled(SecondaryButton)`
  color: ${colors.light100} !important;
`

const StyledLogo = styled(FooterLogo)`
  grid-area: logo;

  ${fresponsive(css`
    width: 140px;
  `)}

  ${media.tablet} {
    width: 100%;
    max-width: 100%;
  }
`

const Addy = styled.div`
  grid-area: address;
  ${textStyles.bodySmall}
  color: ${colors.dark300};
  text-align: center;
  letter-spacing: -0.02em;

  ${fresponsive(css`
    width: 189px;
  `)}
  ${media.tablet} {
    margin-bottom: -35px; // compensate for smaller than normal grid gap
    width: auto;
    text-align: left;
    place-self: start;
  }
`

const Socials = styled.div`
  display: flex;

  ${fresponsive(css`
    gap: 20px;
  `)}

  ${ftablet(css`
    width: 100%;
    grid-area: address;
    place-self: end;
  `)}
`

const Twitter = styled(TwitterSVG)`
  ${Social}

  ${fresponsive(css`
    width: 17px;
  `)}

  ${media.tablet} {
    width: 1.66vw;
  }

  ${media.mobile} {
    width: 4.53vw;
  }
`

const Linkedin = styled(LinkedinSVG)`
  ${Social}

  ${fresponsive(css`
    width: 15px;
  `)}

  ${media.tablet} {
    width: 1.46vw;
  }

  ${media.mobile} {
    width: 4vw;
  }
`

const Legal = styled.div`
  grid-area: legal;
  ${textStyles.bodySmall}
  color: ${colors.dark300};
  padding-bottom: 10px; // compensate for overscroll
  justify-self: flex-start;

  ${fresponsive(css`
    max-width: 906px;
  `)}

  ${media.tablet} {
    ${textStyles.bodyDefault};
    line-height: 145%; // different than standard
  }
  ${media.mobile} {
    ${textStyles.bodyExtraSmall};
    text-align: center;
  }
`

const VR = styled.div`
  width: 1px;
  border-left: 1px dashed ${colors.dark600};
  grid-area: vr;
  position: relative;
  display: none;

  ${media.fullWidth} {
    display: block;
    height: calc(100% + 200px);
  }
  ${media.desktop} {
    display: block;
    height: calc(100% + 13.889vw);
  }
`

const HR = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px dashed ${colors.dark600};
  grid-area: hr;
  display: none;
  ${media.tablet} {
    display: block;
    width: calc(100% + 9.766vw);
  }
  ${media.mobile} {
    display: block;
    width: calc(100% + 8.533vw);
  }
`

const HR2 = styled(HR)`
  grid-area: hr2;
  ${media.mobile} {
    display: none;
  }
`
