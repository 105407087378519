// because this file is a data file, it can have many lines

import { css } from "styled-components"

import fullyResponsive from "utils/fullyResponsive"

const textStyles = {
  h1: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 180px;
    letter-spacing: -0.04em;
    line-height: 85%;
  `),
  h2: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 120px;
    letter-spacing: -0.04em;
    line-height: 85%;
  `),
  h3: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 90px;
    letter-spacing: -0.04em;
    line-height: 85%;
  `),
  h4: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 64px;
    letter-spacing: -0.03em;
    line-height: 100%;
  `),
  h5: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 48px;
    letter-spacing: -0.03em;
    line-height: 110%;
  `),
  h6: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 36px;
    letter-spacing: -0.03em;
    line-height: 115%;
  `),
  h7: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 26px;
    letter-spacing: -0.02em;
    line-height: 120%;
  `),
  h8: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 130%;
  `),
  h9: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 14px;
    letter-spacing: -0.01em;
    line-height: 130%;
  `),
  h10: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 12px;
    letter-spacing: -0.01em;
    line-height: 130%;
  `),
  d1Gellix: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500;
    font-size: 340px;
    letter-spacing: -0.06em;
    line-height: 90%;
  `),
  d3Gellix: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500;
    font-size: 200px;
    letter-spacing: -0.05em;
    line-height: 90%;
  `),
  d1Reckless: fullyResponsive(css`
    font-family: "Reckless";
    font-style: italic;
    font-weight: 300; // light
    font-size: 340px;
    letter-spacing: -0.05em;
    line-height: 90%;
  `),
  d3Reckless: fullyResponsive(css`
    font-family: "Reckless";
    font-style: italic;
    font-weight: 300; // light
    font-size: 200px;
    letter-spacing: -4%;
    line-height: 90%;
  `),
  d4Reckless: fullyResponsive(css`
    font-family: "Reckless";
    font-style: italic;
    font-weight: 300; // light
    font-size: 100px;
    letter-spacing: -4%;
    line-height: 90%;
  `),
  d4Gellix: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500;
    font-size: 100px;
    letter-spacing: -0.05em;
    line-height: 90%;
  `),
  titleLarge: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 600; // semibold
    font-size: 22px;
    letter-spacing: -0.01em;
    line-height: 140%;
  `),
  titleSmall: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 600; // semibold
    font-size: 18px;
    letter-spacing: -0.01em;
    line-height: 145%;
  `),
  kicker: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 300; // light
    font-size: 12px;
    letter-spacing: 0.35em;
    line-height: 105%;
  `),
  caption: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 600; // semibold
    font-size: 13px;
    letter-spacing: 0.08em;
    line-height: 125%;
  `),
  captionSmall: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 600; // semibold
    font-size: 11px;
    letter-spacing: 0.06em;
    line-height: 125%;
  `),
  bodyXXL: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 400; // normal
    font-size: 26px;
    letter-spacing: 0;
    line-height: 130%;
  `),
  bodyXL: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 400; // normal
    font-size: 22px;
    letter-spacing: 0;
    line-height: 130%;
  `),
  bodyLarge: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 400; // normal
    font-size: 18px;
    letter-spacing: 0;
    line-height: 130%;
  `),
  bodyExtraLarge: fullyResponsive(css`
    font-family: "Gellix";
    font-style: normal;
    font-weight: 400; // normal
    font-size: 22px;
    line-height: 130%;
    text-align: center;
  `),
  bodyDefault: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 400; // normal
    font-size: 16px;
    letter-spacing: 0;
    line-height: 130%;
  `),
  bodySmall: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 400; // regular
    font-size: 12px;
    letter-spacing: 0;
    line-height: 130%;
  `),
  bodyExtraSmall: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 400; // regular
    font-size: 9px;
    letter-spacing: 0;
    line-height: 140%;
  `),
  linkLarge: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 18px;
    letter-spacing: 0;
    line-height: 140%;
  `),
  link: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 16px;
    letter-spacing: 0;
    line-height: 145%;
  `),
  linkSmall: fullyResponsive(css`
    font-family: "Gellix";
    font-weight: 500; // medium
    font-size: 14px;
    letter-spacing: 0;
    line-height: 125%;
  `),
  quoteSmall: fullyResponsive(css`
    font-family: "Reckless";
    font-style: italic;
    font-weight: 300; // light
    font-size: 22px;
    letter-spacing: 0;
    line-height: 145%;
  `),
  quoteExtraSmall: fullyResponsive(css`
    font-family: "Reckless";
    font-style: italic;
    font-weight: 300; // light
    font-size: 12px;
    letter-spacing: 0;
    line-height: 145%;
  `),
  standardCoverage: fullyResponsive(css`
    font-family: "Gellix";
    font-style: normal;
    font-weight: 500;
    font-size: 7.60221px;
    line-height: 150%;
  `),
} as const

export const strokeText = css`
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-size: 100%;
  -webkit-text-stroke-width: 1px;
`

export const strokeTextTransparent = css`
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-size: 100%;
  -webkit-text-stroke-width: 1px;
`

export const transparentText = css`
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
`
export default textStyles
