import {
  desktopBreakpoint,
  desktopDesignSize,
  mobileBreakpoint,
  mobileDesignSize,
  tabletBreakpoint,
  tabletDesignSize,
} from "styles/media"

import getMedia from "./getMedia"

export const isBrowser = () => typeof window !== "undefined"

export const addDebouncedEventListener = (
  element: Window | HTMLElement,
  event: string,
  callback: () => void,
  delay = 100
) => {
  let timeout: NodeJS.Timeout

  const debouncedCallback = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => callback(), delay)
  }
  element.addEventListener(event, debouncedCallback)
  return () => element.removeEventListener(event, debouncedCallback)
}

export const vwToPx = (vw: number) => {
  if (isBrowser()) {
    const px = vw * (window.innerWidth / 100)
    return px
  }
  return 0
}

export const vhToPx = (vh: number) => {
  if (isBrowser()) {
    const px = vh * (window.innerHeight / 100)
    return px
  }
  return 0
}

export const getBreakpoint = () => {
  if (typeof window === "undefined") return "mobile"

  const { innerWidth } = window

  if (innerWidth <= mobileBreakpoint) return "mobile"
  if (innerWidth <= tabletBreakpoint) return "tablet"
  if (innerWidth <= desktopBreakpoint) return "desktop"
  return "fullWidth"
}

export function getPxToVw(px: number) {
  const currentBreakpoint = getBreakpoint()

  let conversionValue = desktopDesignSize
  if (currentBreakpoint === "mobile") {
    conversionValue = mobileDesignSize
  } else if (currentBreakpoint === "tablet") {
    conversionValue = tabletDesignSize
  }

  if (isBrowser()) {
    return px / (conversionValue / 100)
  }
  return 0
}

export function getVwToPx(vw: number) {
  if (isBrowser()) {
    return vw * (window.innerWidth / 100)
  }
  return 0
}

export function getResponsivePixels(px: number) {
  const value = getVwToPx(getPxToVw(px))

  // short circuit if we're not using responsive pixels
  return getMedia(px, value, value, value)

  return value
}

export const sleep = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

export function pathnameMatches(pathA: string, pathB: string) {
  return pathA === pathB || pathA === `${pathB}/`
}

// input in the form of rgb(255, 255, 255)
// determine if the color is light or dark
export const isColorLight = (color: string) => {
  const rgb = color.match(/\d+/g)
  if (!rgb) return false
  const r = parseInt(rgb[0], 10)
  const g = parseInt(rgb[1], 10)
  const b = parseInt(rgb[2], 10)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness > 125
}

// if name has 3 words, and the combined character of the first two is <= 12, break after the first two words.
export const evansWordBreaker = (platformName: string | null | undefined) => {
  if (!platformName) return false
  const words = platformName.split(" ")

  if (words.length >= 3) {
    const charLength = words[0].length + words[1].length

    if (charLength <= 12) {
      return true
    }

    return false
  }

  return false
}
