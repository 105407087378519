import React from "react"

import styled, { css } from "styled-components"

import { ReactComponent as BoostLogo } from "images/global/BoostLogo.svg"
import colors, { ColorType, GradientType } from "styles/colors"
import media from "styles/media"
import textStyles from "styles/text"
import HeaderSectionType from "types/HeaderSectionType"
import { fresponsive } from "utils/fullyResponsive"

import DropdownLink from "./DropdownLink"

interface DropdownContentProps {
  section: HeaderSectionType
  themeColor: ColorType | undefined
  gradient: GradientType | undefined
}

export default function DropdownContent({
  section,
  themeColor,
  gradient,
}: DropdownContentProps) {
  const subSections = section.subSections.map(subSection => {
    const sectionLinks = subSection.links?.map(link => {
      return (
        <DropdownLink
          key={link?.slug}
          link={link}
          route={section.route}
          themeColor={themeColor}
          gradient={gradient}
        />
      )
    })

    return (
      <SubSection key={subSection.name}>
        {subSection.name && (
          <SubSectionLabel>{subSection.name}</SubSectionLabel>
        )}
        {sectionLinks && (
          <Links $single={subSection.style === "single"}>{sectionLinks}</Links>
        )}
      </SubSection>
    )
  })

  return (
    <Wrapper>
      <Title>
        <StyledBoostLogo />
        {section.name === "Products" ? "The Platform" : section.name}
      </Title>
      {subSections && <Content>{subSections}</Content>}
    </Wrapper>
  )
}

const SubSection = styled.div`
  width: 100%;
  border-top: 1px dashed ${colors.dark600};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 0;

  padding: 20px 18px;

  &:first-of-type {
    border: none;
  }

  ${media.desktop} {
    padding: 1.389vw 1.25vw;
  }
`

const SubSectionLabel = styled.span`
  ${textStyles.captionSmall}
  color: ${colors.dark500};
  text-transform: uppercase;

  padding-left: 62px;
  margin-bottom: 10px;

  ${media.desktop} {
    padding-left: 4.31vw;
    margin-bottom: 0.69vw;
  }
`

const Links = styled.div<{ $single?: boolean }>`
  width: 100%;

  display: grid;
  grid-template-columns: ${props => (props.$single ? "1fr" : "1fr 1fr")};
  grid-template-rows: auto;
  grid-auto-flow: row;

  ${({ $single }) =>
    fresponsive(css`
      gap: ${$single ? "unset" : "5px 36px"};
    `)}
`

const StyledBoostLogo = styled(BoostLogo)`
  fill: ${colors.dark300};

  width: 20px;
  ${media.desktop} {
    width: 1.389vw;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  :not(:first-child) {
    ${StyledBoostLogo} {
      display: none;
    }
  }
`

const Title = styled.div`
  border-bottom: 1px dashed ${colors.dark600};
  display: flex;
  color: ${colors.dark300};
  ${textStyles.captionSmall};
  align-items: center;
  text-transform: uppercase;
  flex-shrink: 0;

  ${fresponsive(css`
    padding: 0 36px;
    height: 68px;
    gap: 15px;
  `)}
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
