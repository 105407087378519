import React, { useContext, useEffect, useState } from "react"

import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"

import { ScreenContext } from "components/Providers/Screen"
import media from "styles/media"
import HeaderSectionType from "types/HeaderSectionType"
import { fresponsive } from "utils/fullyResponsive"

import headerData from "./data"
import DesktopMenu from "./Desktop/DesktopMenu"
import Logo from "./Logo"
import ResponsiveMenu from "./Responsive/ResponsiveMenu"

export default function Header() {
  const [data, setData] = useState<HeaderSectionType[][] | []>([])

  const contentfulLinks: Queries.HeaderNavigationQuery = useStaticQuery(graphql`
    query HeaderNavigation {
      contentfulNavigation {
        solutions {
          name
          slug
          icon {
            ...ContentfulImageQuery
          }
          navigationDescription
        }
        insuranceProducts {
          name
          slug
          icon {
            ...ContentfulImageQuery
          }
          navigationDescription
          category
        }
        platforms {
          name
          slug
          icon {
            ...ContentfulImageQuery
          }
          navigationDescription
        }
      }
    }
  `)

  useEffect(() => {
    if (contentfulLinks) {
      const newData = [...headerData]
      newData[0][1].subSections[0].links =
        contentfulLinks.contentfulNavigation?.platforms
      newData[1][0].subSections[0].links =
        contentfulLinks.contentfulNavigation?.insuranceProducts?.filter(
          item => item?.category === "Commercial"
        )
      newData[1][0].subSections[1].links =
        contentfulLinks.contentfulNavigation?.insuranceProducts?.filter(
          item => item?.category === "Personal"
        )
      newData[1][0].subSections[2].links =
        contentfulLinks.contentfulNavigation?.insuranceProducts?.filter(
          item => item?.category === "Other"
        )
      newData[2][0].subSections[0].links =
        contentfulLinks.contentfulNavigation?.solutions
      setData(newData)
    }
  }, [contentfulLinks])

  const { mobile, tablet } = useContext(ScreenContext)

  return (
    <Wrapper>
      <Logo showText />
      {mobile || tablet ? (
        <ResponsiveMenu data={data} />
      ) : (
        <DesktopMenu data={data} show />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: center;
  position: fixed;
  z-index: 9;
  width: 100vw;
  transform: translate(0); // position menus relative to this element

  ${fresponsive(css`
    height: 42px;
    padding: 0 112px;
    top: 50px;
  `)}

  ${media.fullWidth} {
    padding: 0 calc((100vw - 1216px) / 2);
  }

  ${media.tablet} {
    height: 5.371vw;
    padding: 0 1.953vw;
    top: 2.637vw;
    display: flex;
    justify-content: space-between;
    position: absolute;
  }
  ${media.mobile} {
    height: 10.667vw;
    padding: 0 5.333vw;
    top: 5.333vw;
    display: flex;
    justify-content: space-between;
    position: absolute;
  }
`
