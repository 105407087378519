import React, { useCallback, useRef } from "react"

import gsap from "gsap"
import ScrollSmoother from "gsap/ScrollSmoother"
import styled, { css } from "styled-components"

import { ReactComponent as PreloaderMarkSVG } from "images/global/PreloaderMark.svg"
import colors from "styles/colors"
import { fmobile, fresponsive, ftablet } from "utils/fullyResponsive"
import getMedia from "utils/getMedia"
import {
  registerLoaderCallback,
  unregisterLoaderCallback,
} from "utils/Loader/LoaderUtils"
import { pageReady } from "utils/pageReady"
import useAnimation from "utils/useAnimation"

export default function BoostPreloader() {
  const wrapper = useRef<HTMLDivElement>(null)
  const logoMark = useRef<SVGSVGElement>(null)

  const runAnimation = useCallback(() => {
    /**
     * expand to fill the screen
     * also change logo color and scale
     */
    const expandShared = {
      ease: "power4.inOut",
      delay: 1.1,
    }
    const elements = wrapper.current?.querySelectorAll("path")
    if (elements)
      gsap.fromTo(elements, {
          filter:
            "brightness(0%) saturate(100%) invert(20%) sepia(57%) saturate(386%) hue-rotate(140deg) brightness(91%) contrast(87%)",
        },
        {
          filter:
            "brightness(100%) saturate(100%) invert(0%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(100%) contrast(100%)",
          ...expandShared,
        })
    if (wrapper.current)
      gsap.to(wrapper.current.children, {
        width: "100vw",
        height: "calc(100vh + 2px)",
        maxHeight: "calc(100vh + 2px)",
        borderRadius: 0,
        stagger: 0.05,
        ...expandShared,
      })
    gsap.to(logoMark.current, {
      scale: () => getMedia(2, 2, 1.5, 1.5),
      ...expandShared,
    })

    /**
     * slide up the preloader
     */
    const slideOutShared = {
      ease: "power4.inOut",
      duration: 1,
      delay: 1.6,
    }
    gsap.to(wrapper.current, {
      y: "-100vh",
      ...slideOutShared,
      onComplete: () => {
        gsap.to(wrapper.current, {
          autoAlpha: 0,
        })
      },
    })
    gsap.fromTo("main > *:first-child, main > :nth-child(2), header", {
        y: "100vh",
      },
      {
        y: 0,
        ...slideOutShared,
        onStart: () => {
          // instant scroll to top
          const smoother = ScrollSmoother.get()
          smoother?.scrollTo(0)
          smoother?.paused(true)
        },
        onComplete: () => {
          ScrollSmoother.get()?.paused(false)
          // clear transforms off these elements
          gsap.set("main > *:first-child, main > :nth-child(2), header", {
            clearProps: "all",
          })
        },
      })
  }, [])

  useAnimation(() => {
    /**
     * skip the preloader when already scrolled (browser behavior)
     */
    if (window.scrollY > window.innerHeight) {
      gsap.to(wrapper.current, {
        autoAlpha: 0,
        delay: 0.4,
      })
      pageReady()
        .finally(() => {
          // wait for everything else to load first
          setTimeout(() => {
            ScrollSmoother.get()?.paused(false)
          }, 0)
        })
        .catch(console.error)
      return
    }

    /**
     * animate in from the side
     */
    if (wrapper.current)
      gsap.to(wrapper.current.children, {
        x: 0,
        y: 0,
        xPercent: -50,
        yPercent: -50,
        ease: "elastic.out(0.5, 0.6)",
        stagger: 0.075,
        duration: 1,
        delay: 0.4,
      })

    /**
     * set initial color for logo
     */
    const contextToRevert = gsap.context(() => {
      const elements = wrapper.current?.querySelectorAll("path")
      if (elements)
        gsap.set(elements, {
          fill: colors.dark600,
        })
    })

    const start = () => {
      contextToRevert.revert()
      runAnimation()
    }

    registerLoaderCallback({
      callback: start,
      duration: 3,
    })
    return () => {
      unregisterLoaderCallback(start)
    }
  }, [runAnimation])

  return (
    <Wrapper ref={wrapper}>
      <Card colorShade={400} />
      <Card colorShade={500} />
      <Card colorShade={600} />
      <Card colorShade={800}>
        <PreloaderMark ref={logoMark} />
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${colors.gradients.primaryHorizontal};
  z-index: 1000;
  pointer-events: none;
`

const Card = styled.div<{ colorShade: 800 | 600 | 500 | 400 }>`
  background-color: ${({ colorShade }) => colors[`dark${colorShade}`]};
  z-index: ${({ colorShade }) => colorShade};

  // center in container
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%) translateX(50vw);
  display: grid;
  place-items: center;
  max-height: 100vh;

  ${fresponsive(css`
    width: 723px;
    height: 425px;
    border-radius: 15px;
  `)}
  ${ftablet(css`
    width: 720px;
    height: 830px;
  `)}
    ${fmobile(css`
    width: 290px;
    height: 512px;
    max-height: 60vh;
  `)}
`

const PreloaderMark = styled(PreloaderMarkSVG)`
  ${fresponsive(css`
    height: 65px;
  `)}
  ${ftablet(css`
    height: 80px;
  `)}
  ${fmobile(css`
    height: 50px;
  `)}
`
