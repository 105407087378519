import React, { useEffect, useRef } from "react"

import gsap from "gsap"
import Observer from "gsap/Observer"
import ScrollSmoother from "gsap/ScrollSmoother"
import styled from "styled-components"

import colors from "styles/colors"
import media from "styles/media"
import loader from "utils/Loader"

const MAX_HEIGHT = 400

/**
 * rate at which the velocity will be decayed
 */
const RATE = 0.9
/**
 * factor by which the velocity will be decreased
 * so that the overscroll resets to 0
 */
const HEIGHT_DAMPER = 0.1
/**
 * multiplier for the velocity caused by the mousewheel
 */
const SCROLL_MULTIPLIER = 15

export default function OverScroll() {
  const wrapper = useRef<HTMLDivElement>(null)

  const setHeight = (heightIn: number) => {
    if (!wrapper.current) return
    const smoother = ScrollSmoother.get()

    if (smoother) {
      const currentScroll = smoother.scrollTop()
      const maxScroll = document.body.scrollHeight - window.innerHeight
      const distanceFromBottom = maxScroll - currentScroll

      // when distance from bottom is 0, allow whole range of motion (100%)
      // when distance from bottom is 500, allow no range of motion (0%)
      const heightRange = 1 - Math.min(1, distanceFromBottom / MAX_HEIGHT)
      const height = heightIn * heightRange

      // adjust size of overscroll element
      gsap.set(wrapper.current, {
        height,
      })

      // adjust elements on screen
      const elements = Array.from(document.querySelectorAll("footer"))
      gsap.set(elements, {
        y: -height + 1,
      })

      // adjust fancy lines
      const footerLines = Array.from(
        wrapper.current.querySelectorAll(".footer-line")
      )

      footerLines.forEach((line, i) => {
        const n = i + 1
        const percent = height / MAX_HEIGHT
        const baseWidth = window.innerWidth
        const modifier = (baseWidth / 7) * n * percent
        gsap.set(line, {
          width: baseWidth - modifier,
          x: modifier / 2,
        })
      })
    }
  }

  /**
   * overscroll effect
   */
  useEffect(() => {
    let canAnimate = true

    let dy = 0
    let height = 0
    let isDragging = false

    const animate = () => {
      if (!canAnimate) return

      if (isDragging) height += dy
      else height += dy - height * HEIGHT_DAMPER
      setHeight(height)

      dy *= RATE
      height = Math.max(0, height)
      height = Math.min(MAX_HEIGHT, height)

      requestAnimationFrame(animate)
    }
    loader.addEventListener("anyEnd", animate)

    const update = (e: Observer) => {
      // if the header includes the event target, don't animate
      const { target } = e.event
      if (target instanceof HTMLElement && target.closest("header")) return

      isDragging = e.isDragging
      const currentScroll = ScrollSmoother.get()?.scrollTop() || 0
      const maxScroll = document.body.scrollHeight - window.innerHeight
      const distanceFromBottom = maxScroll - currentScroll

      if (distanceFromBottom > 500) return

      const offset =
        (e.isDragging ? -e.deltaY / 100 : e.deltaY / 1000) * SCROLL_MULTIPLIER

      dy += offset
    }

    const observer = Observer.create({
      onWheel: e =>
        requestAnimationFrame(() => {
          update(e)
        }),
      onDrag: e =>
        requestAnimationFrame(() => {
          update(e)
        }),
    })

    return () => {
      observer.kill()
      loader.removeEventListener("anyEnd", animate)
      canAnimate = false
    }
  }, [])

  return (
    <Wrapper ref={wrapper}>
      <Footer />
      <After className="footer-line" n={1} />
      <After className="footer-line" n={2} />
      <After className="footer-line" n={3} />
      <After className="footer-line" n={4} />
      <After className="footer-line" n={5} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 0;
  overflow: hidden;
  background: ${colors.gradients.primaryHorizontal};
  z-index: 100;
`

const Footer = styled.div`
  background-color: ${colors.dark800};
  width: 100%;
  position: absolute;
  top: -1px;

  height: 30px;
  border-radius: 0 0 30px 30px;
  ${media.desktop} {
    height: 2.083vw;
    border-radius: 0 0 2.083vw 2.083vw;
  }
`

const After = styled.div<{ n: number }>`
  background: transparent;
  border: 1px solid ${colors.dark600};
  top: 0;
  left: 0;
  height: ${({ n }) => n * 18 + 26}px;
  width: 100%;
  position: absolute;
  z-index: -1;
  transform-origin: top center;
  opacity: ${({ n }) => 1 - n * 0.15};

  border-radius: 0 0 30px 30px;
  ${media.desktop} {
    border-radius: 0 0 2.083vw 2.083vw;
  }
`
