import React from "react"

import styled from "styled-components"

import colors from "styles/colors"
import media from "styles/media"
import textStyles from "styles/text"
import HeaderSectionType from "types/HeaderSectionType"

import SectionItems from "./SectionItems"

interface HeaderSectionProps {
  sectionList: HeaderSectionType[]
}

export default function HeaderSection({ sectionList }: HeaderSectionProps) {
  const sections = sectionList.map(section => {
    const subSections = section.subSections.map(subSection => {
      return (
        <SubSection key={Math.random()}>
          {subSection.name && (
            <SubSectionName>
              <Line />
              <span>{subSection.name}</span>
            </SubSectionName>
          )}
          <Links>
            <SectionItems
              sectionRoute={section.route}
              sectionLinks={subSection.links}
              color={section.color}
              gradient={section.gradient}
            />
          </Links>
        </SubSection>
      )
    })

    return (
      <Section key={Math.random()}>
        {section.name && <SectionName>{section.name}</SectionName>}
        {subSections}
      </Section>
    )
  })

  return <Container key={sectionList[0].name}>{sections}</Container>
}

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.tablet} {
    gap: 0.49vw;
  }

  ${media.mobile} {
    gap: 1.33vw;
  }
`

const SubSectionName = styled.div`
  ${textStyles.captionSmall}
  grid-column: 1 / -1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  color: ${colors.dark500};

  span {
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${colors.darkTeal};
  }

  ${media.tablet} {
    height: 1.37vw;
    margin-bottom: 0.977vw;

    span {
      padding-right: 0.49vw;
    }
  }

  ${media.mobile} {
    height: 3.73vw;
    margin-bottom: 2.667vw;

    span {
      padding-right: 1.33vw;
    }
  }
`

const Line = styled.div`
  border-top: 1px dashed ${colors.dark600};
  width: 100%;
`

const Links = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${media.mobile} {
    gap: 0 6.67vw;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    padding-bottom: 0;
  }

  ${media.tablet} {
    gap: 1.95vw;
    padding: 0.977vw 3.906vw 3.906vw 2.93vw;
  }

  ${media.mobile} {
    gap: 5.33vw;
    padding: 2.67vw 4vw 5.33vw;
  }
`

const SectionName = styled.span`
  ${textStyles.caption}
  text-transform: uppercase;
  color: ${colors.dark300};
`

const Container = styled.div`
  border-top: 1px dashed ${colors.dark600};
  display: flex;
  flex-direction: column;
`
