import React, { useRef } from "react"

import styled, { css } from "styled-components"

import SecondaryButton from "components/Buttons/Secondary"
import colors from "styles/colors"
import HeaderSectionType from "types/HeaderSectionType"
import { fresponsive } from "utils/fullyResponsive"
import useAdaptiveColor from "utils/useAdaptiveColor"

import DropdownContent from "./DropdownContent"
import DropdownButton from "./HeaderDropdown"

interface DesktopMenuProps {
  show: boolean
  data: HeaderSectionType[][]
}

export default function DesktopMenu({ show, data }: DesktopMenuProps) {
  const links = useRef<HTMLDivElement>(null)

  useAdaptiveColor(
    () => links.current?.querySelectorAll("button"),
    {
      color: colors.dark800,
      stroke: colors.dark800,
    },
    {
      color: colors.light100,
      stroke: colors.light100,
    }
  )

  const dropdowns = data.map(sectionList => {
    const sections = sectionList.map(section => (
      <DropdownContent
        section={section}
        themeColor={section.color}
        gradient={section.gradient}
        key={section.name}
      />
    ))

    return (
      <DropdownButton name={sectionList[0].name} key={sectionList[0].name}>
        {sections}
      </DropdownButton>
    )
  })

  return (
    <>
      <Links show={show} ref={links}>
        {dropdowns}
      </Links>

      <SecondaryButton variant="small" to="/contact-us">
        Contact&nbsp;Us
      </SecondaryButton>
    </>
  )
}

const Links = styled.div<{ show: boolean }>`
  display: flex;

  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "all" : "none")};
  transition: opacity 0.3s ease-in-out;

  ${fresponsive(css`
    gap: 66px;
  `)}

  :focus-within {
    opacity: 1;
    pointer-events: all;
  }
`
