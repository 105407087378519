import { useEffect, useState } from "react"

import gsap from "gsap"

import { isColorLight } from "./functions"

export default function useAdaptiveColor(
  getElements: () => Element | ArrayLike<Element> | undefined | null,
  lightStyles: gsap.TweenVars,
  darkStyles: gsap.TweenVars
) {
  // array of booleans, one for each element
  const [isLight, setIsLight] = useState<boolean[]>([])

  useEffect(() => {
    const updateStyles = () => {
      const elOrEls = getElements()
      const allElements = elOrEls instanceof Element ? [elOrEls] : elOrEls
      const newIsLight: boolean[] = []

      if (allElements) {
        Array.from(allElements).forEach((targetEl, i) => {
          if (targetEl) {
            // get the element currently under the logo
            const position = targetEl.getBoundingClientRect()
            const elements = document.elementsFromPoint(
              position.x + position.width / 2,
              position.y + position.height / 2
            )
            const header = document.querySelector("header")
            if (!header) return

            // find the first element not in the header and has a background color
            const backgroundEl = elements.find(el => {
              return (
                !header.contains(el) &&
                window
                  .getComputedStyle(el)
                  .getPropertyValue("background-color") !== "rgba(0, 0, 0, 0)"
              )
            })

            if (backgroundEl) {
              // get the background color of the element
              const color = window
                .getComputedStyle(backgroundEl)
                .getPropertyValue("background-color")

              if (isColorLight(color)) {
                newIsLight[i] = true
              } else {
                newIsLight[i] = false
              }
            }
          }
        })

        // if the array of booleans has changed, update the state
        if (newIsLight.toString() !== isLight.toString()) {
          setIsLight(newIsLight)
        }
      }
    }

    const interval = setInterval(updateStyles, 250)

    return () => clearInterval(interval)
  }, [darkStyles, getElements, isLight, lightStyles])

  useEffect(() => {
    const elOrEls = getElements()
    const allElements = elOrEls instanceof Element ? [elOrEls] : elOrEls
    Array.from(allElements || []).forEach((targetEl, i) => {
      const isLightEl = isLight[i] || false
      if (isLightEl) {
        gsap.to(targetEl, {
          duration: 0.2,
          ease: "power3.inOut",
          ...lightStyles,
        })
      } else {
        gsap.to(targetEl, {
          duration: 0.2,
          ease: "power3.inOut",
          ...darkStyles,
        })
      }
    })
  }, [darkStyles, getElements, isLight, lightStyles])
}
