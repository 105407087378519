import React, { useEffect, useRef, useState } from "react"

import { useLocation } from "@reach/router"
import gsap from "gsap"
import styled from "styled-components"

import { ReactComponent as DropdownArrow } from "images/global/DropdownArrow.svg"
import colors from "styles/colors"
import media from "styles/media"
import textStyles from "styles/text"
import useAdaptiveColor from "utils/useAdaptiveColor"
import useAnimation from "utils/useAnimation"

interface DropdownContainerProps {
  children: React.ReactNode
  name: string | undefined
}

const OPEN_DURATION = 0.5
const CLOSE_DURATION = 0.2

export default function HeaderDropdown({
  children,
  name,
}: DropdownContainerProps) {
  const [showDropdown, setShowDropdown] = useState(false)
  const wrapper = useRef<HTMLDivElement>(null)
  const dropdown = useRef<HTMLDivElement>(null)
  const arrow = useRef<HTMLDivElement>(null)
  const location = useLocation()

  /**
   * when navigating to a new page, close the dropdown
   */
  useEffect(() => {
    setShowDropdown(false)
    gsap.set("header", {
      pointerEvents: "none",
    })
    gsap.delayedCall(0.5, () => {
      gsap.set("header", {
        pointerEvents: "auto",
      })
    })
  }, [location])

  /**
   * apply dropdown with animation when state changes
   */
  useAnimation(
    () => {
      const defaults = {
        delay: showDropdown ? CLOSE_DURATION : 0,
        duration: showDropdown ? OPEN_DURATION : CLOSE_DURATION,
        ease: showDropdown ? "power3.out" : "power3.in",
      }

      gsap.set(dropdown.current, { visibility: "visible" })
      gsap.to(dropdown.current, {
        width: showDropdown ? "auto" : 150,
        height: showDropdown ? "auto" : 0,
        ...defaults,
        onComplete: () => {
          if (!showDropdown) {
            gsap.set(dropdown.current, { visibility: "hidden" })
          }
        },
      })
      if (dropdown.current)
        gsap.to(dropdown.current.children, {
          y: showDropdown ? 0 : -100,
          ...defaults,
        })
      gsap.to(arrow.current, {
        transform: showDropdown
          ? "rotateX(55deg) rotate(45deg)"
          : "rotateX(90deg) rotate(45deg)",
        ...defaults,
      })
    },
    [showDropdown],
    {
      kill: true,
    }
  )

  /**
   * handle outside click
   */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapper.current &&
        !wrapper.current.contains(event.target as Node) &&
        showDropdown
      ) {
        setShowDropdown(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [showDropdown])

  /**
   * opening and closing dropdown on click or touch
   */
  let shouldCancelLeave = false
  let shouldCancelEnter = false
  const handleMouseEnter = () => {
    shouldCancelLeave = true
    shouldCancelEnter = false
    setTimeout(() => {
      if (!shouldCancelEnter) setShowDropdown(true)
    }, 50)
  }

  const handleMouseLeave = () => {
    shouldCancelLeave = false
    shouldCancelEnter = true
    setTimeout(() => {
      if (!shouldCancelLeave) setShowDropdown(false)
    }, 100)
  }

  const handleTouchStart = () => {
    shouldCancelEnter = true
    shouldCancelLeave = true
    setShowDropdown(!showDropdown)
  }

  useAdaptiveColor(
    () => dropdown.current,
    {
      boxShadow: "2px 8px 40px rgba(0, 0, 0, 0.00)",
    },
    {
      boxShadow: "0px 10px 80px rgba(0, 0, 0, 0.15)",
    }
  )

  return (
    <ButtonWrapper
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
    >
      <DropdownButton
        type="button"
        onClick={e => {
          if (e.clientX === 0) setShowDropdown(!showDropdown)
        }}
      >
        {name}
        <StyledDropdownArrow $flipped={showDropdown} />
      </DropdownButton>
      <Arrow ref={arrow} />
      <DropdownContainer ref={dropdown}>{children}</DropdownContainer>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  position: relative;
`

const DropdownButton = styled.button`
  background: transparent;
  border: none;
  ${textStyles.h9}
  display: flex;
  align-items: center;
  cursor: pointer;

  // increases the hover area
  :after {
    content: "";
    top: -20px;
    left: -20px;
    width: 300%;
    bottom: -50px;
    position: absolute;
  }
`

const StyledDropdownArrow = styled(DropdownArrow)<{ $flipped: boolean }>`
  transform: ${props => (props.$flipped ? "rotateX(180deg)" : "rotateX(0deg)")};
  transition: transform 0.35s ease-in-out;

  width: 7px;
  margin-left: 4px;
  ${media.desktop} {
    width: 0.486vw;
    margin-left: 0.278vw;
  }
`

const Arrow = styled.div`
  content: "";
  position: absolute;
  background-color: ${colors.dark800};
  transform: rotateX(90deg) rotate(45deg);

  top: calc(100% + 14px);
  left: 20px;
  width: 30px;
  height: 30px;
  border-radius: 3px 0 0 0;
  ${media.desktop} {
    top: calc(100% + 0.972vw);
    left: 1.389vw;
    width: 2.083vw;
    height: 2.083vw;
    border-radius: 0.208vw 0 0 0;
  }
`

const DropdownContainer = styled.div`
  background-color: ${colors.dark800};

  position: absolute;
  display: flex;
  overflow: hidden;
  height: 0;
  width: 0;
  z-index: 9;

  top: calc(100% + 28px);
  left: -25px;
  border-radius: 15px;
  ${media.desktop} {
    top: calc(100% + 1.944vw);
    left: -1.736vw;
    border-radius: 1.042vw;
  }
`
