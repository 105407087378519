import React, { useEffect } from "react"

import styled, { createGlobalStyle, css } from "styled-components"

import Footer from "components/Footer"
import Header from "components/Header"
import Scroll from "components/Scroll"
import colors from "styles/colors"
import fullyResponsive from "utils/fullyResponsive"
import { isBrowser } from "utils/functions"
import { usePageLoad } from "utils/Loader/TransitionUtils"
import { useTrackPageReady } from "utils/pageReady"

import Overscroll from "./Footer/Overscroll"
import PageTransition from "./PageTransition"
import BoostPreloader from "./Preloader"

interface LayoutProps {
  children: React.ReactNode
  id?: string
}

export default function Layout({ children, id = undefined }: LayoutProps) {
  usePageLoad()
  useTrackPageReady()

  useEffect(() => {
    // add a 100vh css variable to the root element
    const update = () => {
      const vh = isBrowser() ? `${window.innerHeight}px` : "100vh"
      const oneVh = isBrowser() ? `${window.innerHeight * 0.01}px` : "1vh"
      document.documentElement.style.setProperty("--vh", oneVh)
      document.documentElement.style.setProperty("--hundred-vh", vh)
    }
    update()
    window.addEventListener("resize", update)
    return () => window.removeEventListener("resize", update)
  })

  return (
    <>
      <GlobalStyle />
      <BoostPreloader />
      <PageTransition />
      <Scroll>
        <Header />
        <Main id={id}>{children}</Main>
        <Footer />
      </Scroll>
      <Overscroll />
    </>
  )
}

export const HeaderSpacer = styled.div`
  height: 92px;
`

const GlobalStyle = createGlobalStyle`${css`
  body {
    background: ${colors.dark800}; // need this for useAdaptiveColor to work properly. set to any color as long as it's not transparent
    color: ${colors.dark800}; // override the default black text color
  }
  html {
    background: ${colors.dark800};
  }

  * {
    text-rendering: geometricPrecision; // need this so that fonts match figma
  }

  // Temporary fixes for visbug
  vis-bug {
    position: fixed;
    z-index: var(--layer-1);
  }

  visbug-metatip,
  visbug-handles,
  visbug-ally {
    position: absolute;
    z-index: var(--layer-top);
  }

  main > * {
    margin-top: -1px;
  }

  /** restore default focus states for elements that need them */
  *:focus-visible {
    outline: 2px solid ${colors.green400}88;
  }
`}`

const Main = styled.main`
  position: relative;
  overflow: hidden;
  min-height: 75vh;
  z-index: 3;
  background: ${colors.mainWhite};
  ${fullyResponsive(
    css`
      margin-bottom: -40px;
    `
  )}
`
