import React, { useRef } from "react"

import styled, { css } from "styled-components"

import colors from "styles/colors"
import media from "styles/media"
import textStyles from "styles/text"
import useAdaptiveColor from "utils/useAdaptiveColor"

interface SecondaryButtonProps {
  className?: string
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export default function MenuButton({
  className = "",
  state,
}: SecondaryButtonProps) {
  const barsRef = useRef<HTMLButtonElement>(null)
  const [menuIsOpen, setMenuIsOpen] = state

  useAdaptiveColor(
    () => barsRef.current?.children,
    { backgroundColor: colors.dark800 },
    { backgroundColor: colors.light100 }
  )

  return (
    <Button
      aria-label="Open site menu"
      ref={barsRef}
      className={className}
      onClick={() => setMenuIsOpen(!menuIsOpen)}
      menuIsOpen={menuIsOpen}
    >
      <Bars showX={menuIsOpen} />
      <Bars showX={menuIsOpen} />
      <Bars showX={menuIsOpen} />
    </Button>
  )
}

const Button = styled.button<{ menuIsOpen: boolean }>`
  position: relative;
  z-index: 10;
  border: none;
  background: none;
  ${textStyles.h9};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${media.tablet} {
    width: 5.859vw;
    height: 5.859vw;
  }
  ${media.mobile} {
    width: 10.667vw;
    height: 10.667vw;
  }

  transition: translate 0.3s ease-in-out;
  ${({ menuIsOpen }) =>
    menuIsOpen &&
    css`
      background-color: ${colors.dark800};
      border-radius: 50%;
      ${media.tablet} {
        translate: -0.977vw 0.488vw;
      }
      ${media.mobile} {
        translate: -0.533vw 0;
      }
    `}

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border-radius: 99px;
    border: 1px solid transparent;
    background: ${colors.gradients.primaryHorizontal};
    background-size: 120% 120%;
    background-repeat: no-repeat;
    background-position: center;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    mask-composite: exclude;
  }
`

const Bars = styled.div<{ showX: boolean }>`
  --half-height: 1px;
  ${media.tablet} {
    width: 3.223vw;
    left: calc(50% - 1.611vw);
    --half-height: 0.5px;
  }
  ${media.mobile} {
    width: 5.867vw;
    left: calc(50% - 2.933vw);
    --half-height: 0.5px;
  }

  position: absolute;
  top: calc(50% - var(--half-height));
  height: calc(var(--half-height) * 2);
  border-radius: var(--half-height);
  background-color: ${colors.dark800};
  transition: background-color 0.2s ease-in-out, top 0.2s ease-in-out,
    rotate 0.2s ease-in-out;

  :nth-child(1) {
    ${media.tablet} {
      top: calc(50% - 0.586vw - var(--half-height));
    }
    ${media.mobile} {
      top: calc(50% - 1.6vw - var(--half-height));
    }
  }

  :nth-child(2) {
    ${media.tablet} {
      top: calc(50% + 0.586vw - var(--half-height));
    }
    ${media.mobile} {
      top: calc(50% + 1.6vw - var(--half-height));
    }
  }

  ${({ showX }) =>
    showX &&
    css`
      background-color: ${colors.light100} !important;
      rotate: 45deg;

      :nth-child(1) {
        rotate: -45deg;
        top: calc(50% - var(--half-height));
      }

      :nth-child(2) {
        rotate: -45deg;
        top: calc(50% - var(--half-height));
      }
    `}
`
