import React, { useRef, useState } from "react"

import gsap from "gsap"
import styled, { css } from "styled-components"

import colors from "styles/colors"
import { fresponsive } from "utils/fullyResponsive"
import loader from "utils/Loader"
import useAnimation from "utils/useAnimation"

export default function PageTransition() {
  const firstSet = useRef<HTMLDivElement>(null)
  const secondSet = useRef<HTMLDivElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)
  const [showLooper, setShowLooper] = useState(false)

  /**
   * create the looping animation
   */
  useAnimation(() => {
    if (!firstSet.current || !secondSet.current) return
    if (!showLooper) return
    const loopingTimeline = gsap.timeline({
      repeat: -1,
      defaults: {
        duration: 0.5,
        ease: "back.inOut",
      },
    })

    loopingTimeline
      // animate the first set out the top
      .fromTo(firstSet.current.children, {
          yPercent: -50,
        },
        {
          stagger: 0.02,
          yPercent: -150,
        }, 1)
      .fromTo(firstSet.current, {
          opacity: 1,
        },
        {
          opacity: 0,
        }, 1)

      // animate the second set in the bottom
      .fromTo(secondSet.current.children, {
          yPercent: 0,
        },
        {
          stagger: 0.02,
          yPercent: -50,
        }, 1.1)
      .fromTo(secondSet.current, {
          opacity: 0,
        },
        {
          opacity: 1,
        }, 1.1)
  }, [showLooper])

  /**
   * handle page transition
   */
  useAnimation(() => {
    let wrapperTimeout = setTimeout(() => {}, 0)
    let looperTimeout = setTimeout(() => {}, 0)
    let hasFired = false

    const onTransitionStart = () => {
      clearTimeout(wrapperTimeout)
      clearTimeout(looperTimeout)
      wrapperTimeout = setTimeout(() => {
        hasFired = true
        gsap.to(wrapper.current, {
          yPercent: 0,
          y: 0,
          duration: 0.3,
        })
      }, 1000)

      looperTimeout = setTimeout(() => {
        setShowLooper(true)
      }, 2000)
    }

    const onTransitionComplete = () => {
      clearTimeout(wrapperTimeout)
      clearTimeout(looperTimeout)
      if (!hasFired) {
        gsap.set(wrapper.current, {
          yPercent: 100,
        })
        return
      }
      hasFired = false
      setShowLooper(false)
      gsap.to(wrapper.current, {
        yPercent: -100,
        duration: 0.3,
        onComplete: () => {
          gsap.set(wrapper.current, {
            yPercent: 100,
          })
        },
      })
    }

    const unloaded = () => {
      clearTimeout(wrapperTimeout)
      clearTimeout(looperTimeout)
      gsap.set(wrapper.current, {
        yPercent: 100,
      })
    }

    loader.addEventListener("transitionStart", onTransitionStart)
    loader.addEventListener("transitionEnd", onTransitionComplete)
    window.addEventListener("beforeunload", unloaded)
    window.addEventListener("popstate", unloaded)

    return () => {
      clearTimeout(wrapperTimeout)
      clearTimeout(looperTimeout)
      loader.removeEventListener("transitionStart", onTransitionStart)
      loader.removeEventListener("transitionEnd", onTransitionComplete)
      window.removeEventListener("beforeunload", unloaded)
      window.removeEventListener("popstate", unloaded)
    }
  }, [])

  return (
    <Wrapper ref={wrapper}>
      <Clipper show={showLooper}>
        <div ref={firstSet}>
          <PreloaderSquare n={0} />
          <PreloaderSquare n={1} />
          <PreloaderSquare n={2} />
          <PreloaderSquare n={3} />
        </div>

        <div ref={secondSet}>
          <PreloaderSquare n={0} />
          <PreloaderSquare n={1} />
          <PreloaderSquare n={2} />
          <PreloaderSquare n={3} />
        </div>
      </Clipper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${colors.dark600};
  display: grid;
  place-items: center;
  transform: translateY(100%);
  pointer-events: none;
`

const Clipper = styled.div<{ show: boolean }>`
  position: relative;
  ${fresponsive(css`
    width: 100px;
    height: 100px;
    transform: translate(-10px, -10px);
  `)}
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`

const PreloaderSquare = styled.div<{ n: number }>`
  transform: scale(0.76) translateY(${({ n }) => n * 18}px) translateX(-50%)
    rotateX(45deg) rotate(45deg);
  opacity: ${({ n }) => 1 - n * 0.25};
  position: absolute;
  top: 50%;
  left: 50%;
  ${fresponsive(css`
    border: 3px solid ${colors.dark400};
    border-radius: 10px;
    width: 122px;
    height: 119px;
  `)}
`
