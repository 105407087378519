import React, { useContext } from "react"

import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"

import { ScreenContext } from "components/Providers"
import colors from "styles/colors"
import media from "styles/media"
import textStyles from "styles/text"
import { fresponsive } from "utils/fullyResponsive"
import links from "utils/links"
import UniversalLink from "utils/Loader/UniversalLink"

export default function FooterLinks() {
  const { fullWidth, desktop, tablet, mobile } = useContext(ScreenContext)

  const contentfulLinks: Queries.FooterNavigationQuery = useStaticQuery(graphql`
    query FooterNavigation {
      contentfulNavigation {
        solutions {
          name
          slug
        }
        insuranceProducts {
          name
          slug
        }
        platforms {
          name
          slug
        }
      }
    }
  `)

  const platformLinks = contentfulLinks.contentfulNavigation?.platforms?.map(
    item => (
      <Link key={item?.slug} to={`/platform/${item?.slug || ""}`}>
        {item?.name}
      </Link>
    )
  )
  const solutionLinks = contentfulLinks.contentfulNavigation?.solutions?.map(
    item => (
      <Link key={item?.slug} to={`/solution/${item?.slug || ""}`}>
        {item?.name}
      </Link>
    )
  )
  const productLinks =
    contentfulLinks.contentfulNavigation?.insuranceProducts?.map(item => (
      <Link key={item?.slug} to={`/product/${item?.slug || ""}`}>
        {item?.name}
      </Link>
    ))

  return (
    <Wrapper>
      {(fullWidth || desktop) && (
        <>
          <Column>
            <Heading>Platform</Heading>
            <Link to="/platform">Overview</Link>
            {platformLinks}
            <Heading>Company</Heading>
            <Link to="/careers">Careers</Link>
          </Column>
          <Column>
            <Heading>Insurance Products</Heading>
            {productLinks}
          </Column>
          <Column>
            <Heading>Solutions</Heading>
            {solutionLinks}
            <Heading>Developers</Heading>
            <Link to="/developer">Developer Overview</Link>
            <Link to={links.apiDocs}>API Documentation</Link>
          </Column>
          <Column>
            <Heading>Resources</Heading>
            <Link to="/blog">Blog</Link>
            <Link to="/media">Media</Link>
            <Heading>Legal</Heading>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/electronic-transactions">Electronic Transactions</Link>
            <Link to="/licenses">Licenses</Link>
          </Column>
        </>
      )}
      {tablet && (
        <>
          <Column>
            <Heading>Platform</Heading>
            <Link to="/platform">Overview</Link>
            {platformLinks}
            <Heading>Legal</Heading>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/electronic-transactions">Electronic Transactions</Link>
            <Link to="/licenses">Licenses</Link>
          </Column>
          <Column>
            <Heading>Insurance Products</Heading>
            {productLinks}
            <Heading>Company</Heading>
            <Link to="/careers">Careers</Link>
          </Column>
          <Column>
            <Heading>Solutions</Heading>
            {solutionLinks}
            <Heading>Developers</Heading>
            <Link to="/developer">Developer Overview</Link>
            <Link to={links.apiDocs}>API Documentation</Link>
            <Heading>Resources</Heading>
            <Link to="/blog">Blog</Link>
            <Link to="/media">Media</Link>
          </Column>
        </>
      )}
      {mobile && (
        <Column>
          <Heading>Platform</Heading>
          <Link to="/platform">Overview</Link>
          {platformLinks}
          <Heading>Insurance Products</Heading>
          {productLinks}
          <Heading>Solutions</Heading>
          {solutionLinks}
          <Heading>Developers</Heading>
          <Link to="/developer">Developer Overview</Link>
          <Link to={links.apiDocs}>API Documentation</Link>
          <Heading>Resources</Heading>
          <Link to="/blog">Blog</Link>
          <Link to="/media">Media</Link>
          <Heading>Legal</Heading>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/electronic-transactions">Electronic Transactions</Link>
          <Link to="/licenses">Licenses</Link>
        </Column>
      )}
    </Wrapper>
  )
}

const Link = styled(UniversalLink)`
  ${textStyles.h9}
  color: ${colors.light100};
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  margin: 5px 0;

  ${media.desktop} {
    margin: 0.347vw 0;
  }

  ${media.tablet} {
    ${textStyles.bodyLarge}
  }

  ${media.mobile} {
    ${textStyles.bodySmall}
  }

  // underline on hover
  :after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: ${colors.gradients.primaryHorizontal};
    transition: width 0.3s;
  }
  :hover:after {
    width: 100%;
  }
`

const Wrapper = styled.div`
  grid-area: links;
  justify-self: space-between;
  display: flex;
  width: 100%;

  ${fresponsive(css`
    gap: 45px;
  `)}

  ${media.tablet} {
    display: grid;
    grid-template-columns: repeat(3, 161fr);
    gap: 6.055vw;
  }
  ${media.mobile} {
    grid-template-columns: 1fr;
    gap: 8vw;
    width: 100%;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 161px;
  ${media.desktop} {
    min-width: 11.181vw;
  }
  ${media.tablet} {
    min-width: 17.09vw;
  }
  ${media.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 8vw;
  }
`

const Heading = styled.div`
  ${textStyles.h7}
  color: ${colors.dark300};
  letter-spacing: -0.02em;

  margin-bottom: 15px;
  :not(:first-child) {
    margin-top: 25px;
  }
  ${media.desktop} {
    margin-bottom: 1.042vw;
    :not(:first-child) {
      margin-top: 1.736vw;
    }
  }

  ${media.mobile} {
    ${textStyles.h8}
    position: relative;
    padding-top: 2.67vw;
    grid-column: span 2;

    :before {
      content: "";
      position: absolute;
      // 100vw causes 1px of overflow on touch devices because of the way overflows are handled
      width: calc(100vw - 1px);
      height: 1px;
      top: 0;
      left: -4vw;
      border-top: 1px dashed ${colors.dark600};
    }
  }
`
