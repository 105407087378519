import React from "react"

import styled, { css } from "styled-components"

import { ReactComponent as ArrowSVG } from "images/global/Arrow.svg"
import colors from "styles/colors"
import textStyles from "styles/text"
import fullyResponsive from "utils/fullyResponsive"
import UniversalLink from "utils/Loader/UniversalLink"

interface PrimaryButtonProps {
  variant?: "small" | "large" | "smallArrow" | "largeArrow"
  children: React.ReactNode
  className?: string
  to: string
}

export default function PrimaryButton({
  children,
  className = "",
  variant = "large",
  to,
}: PrimaryButtonProps) {
  const arrow = variant.includes("Arrow")
  return (
    <Button to={to} className={className} $large={variant.includes("large")}>
      {children}
      {arrow && <Arrow $large={variant.includes("large")} />}
    </Button>
  )
}

export const Arrow = styled(ArrowSVG)<{ $large: boolean }>`
  fill: ${colors.dark800};
  transition: transform 0.2s ease-in-out;

  ${({ $large }) =>
    $large
      ? fullyResponsive(css`
          width: 23px;
          height: 8px;
        `)
      : fullyResponsive(css`
          width: 20px;
          height: 7px;
        `)}
`

const Button = styled(UniversalLink)<{ $large: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${colors.dark800};
  width: fit-content;
  background: ${colors.gradients.primaryHorizontal};
  border: 1px solid transparent;
  border-radius: 999px;
  background-size: calc(100% + 6px); //compensate for border
  background-position: center;
  cursor: pointer;
  z-index: 1;

  ${({ $large: large }) => (large ? textStyles.h8 : textStyles.h9)}
  ${({ $large: large }) =>
    fullyResponsive(
      large
        ? css`
            padding: 18px 30px;
            gap: 15px;
          `
        : css`
            padding: 9px 20px;
            gap: 10px;
          `
    )}

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 999px;
    background: ${colors.light200};
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  :focus-visible {
    :after {
      opacity: 0.5;
    }
  }

  :hover {
    border: 1px solid ${colors.light400};

    :after {
      opacity: 1;
    }

    ${Arrow} {
      transform: translateX(5px);
    }
  }
`
