import React, { MouseEventHandler } from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import gsap from "gsap"
import styled, { css } from "styled-components"

import { ReactComponent as Arrow } from "images/global/Arrow.svg"
import colors, { ColorType, GradientType } from "styles/colors"
import media from "styles/media"
import textStyles from "styles/text"
import type { Link, ContentfulLink } from "types/HeaderSectionType"
import { fresponsive } from "utils/fullyResponsive"
import UniversalLink from "utils/Loader/UniversalLink"

export default function DropdownLink({
  link,
  route,
  themeColor,
  gradient,
}: {
  link: Link | ContentfulLink | null
  route: string | undefined
  themeColor: ColorType | undefined
  gradient: GradientType | undefined
}) {
  const onMouseEnter: MouseEventHandler = e => {
    const target = e.currentTarget
    gsap.to(target, {
      "--progress": "0%",
      duration: 0.7,
      ease: "power3.inOut",
    })
  }

  const onMouseLeave: MouseEventHandler = e => {
    const target = e.currentTarget
    gsap.to(target, {
      "--progress": "100%",
      duration: 0.7,
      ease: "power3.inOut",
    })
  }

  if (link === null) return <div key={Math.random()} />

  const description = link.navigationDescription

  const image =
    typeof link.icon === "string" ? (
      <img src={link.icon || ""} alt={link.name || "nav icon"} />
    ) : (
      link.icon?.gatsbyImageData && (
        <GatsbyImage
          className="gatsby-image"
          image={link.icon.gatsbyImageData}
          alt={link.icon.description || link.name || "nav icon"}
        />
      )
    )

  const fullRoute = route ? `${route}/${link.slug || ""}` : link.slug || "/404"

  const color = themeColor ?? "green500"

  return (
    <Wrapper
      key={link.slug}
      to={fullRoute}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      large={link.style === "large"}
    >
      {image || <div />}

      <LinkHead $color={color} $gradient={gradient}>
        {link.name}
        <ColoredArrow $theme={colors[color] as string} />
      </LinkHead>
      {!image && <div />}
      {description && <LinkDesc>{description}</LinkDesc>}
    </Wrapper>
  )
}

const ColoredArrow = styled(Arrow)<{ $theme: string }>`
  fill: ${props => props.$theme};
  transition: 0.2s ease-in-out;
  width: 16px;
  margin-left: 5px;
  ${media.desktop} {
    width: 1.111vw;
    margin-left: 0.347vw;
  }
`

const Wrapper = styled(UniversalLink)<{ large: boolean }>`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  text-decoration: none;
  position: relative;
  --progress: 100%;
  align-items: center;

  ${fresponsive(css`
    gap: 4px 12px;
    padding: 18px 20px 20px 16px;
    min-height: 92px;
  `)}

  .gatsby-image,
  img {
    object-fit: contain;
    grid-row: span 2;
    width: 35px;
    max-width: none;
    height: 35px;
    ${media.desktop} {
      width: 2.431vw;
      height: 2.431vw;
    }
  }

  ${({ large }) =>
    large &&
    css`
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      flex-grow: 1;
      justify-content: space-between;

      .gatsby-image,
      img {
        object-fit: contain;
        margin-bottom: 10px;
        width: 170px;
        height: 170px;
        ${media.desktop} {
          margin-bottom: 0.694vw;
          width: 11.806vw;
          width: 11.806vw;
        }
      }
    `}

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.dark700};
    z-index: -1;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  :after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: ${colors.gradients.primaryHorizontal};
    background-repeat: none;
    background-size: 105%;
    background-position: center;
    z-index: -2;
    border-radius: 10px;
    transition: opacity transform 0.2s ease-in-out;
    border: 2px solid transparent;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    mask-composite: exclude;
    clip-path: polygon(
      100% 50%,
      150% 0%,
      calc(calc(4 * var(--progress)) - 50%) 0%,
      calc(2 * var(--progress)) 50%,
      100% 50%,
      0% 50%,
      -50% 100%,
      calc(350% - calc(4 * var(--progress))) 100%,
      calc(200% - calc(2 * var(--progress))) 50%
    );
  }
  :hover {
    ${ColoredArrow} {
      transform: translateX(5px);
      transition: 0.2s ease-in-out;
    }

    :before {
      opacity: 1;
    }
  }
`

const LinkHead = styled.div<{
  $color: ColorType
  $gradient: GradientType | undefined
}>`
  ${textStyles.h9};
  ${({ $gradient, $color }) =>
    $gradient
      ? css`
          background: ${colors.gradients[$gradient]};
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
          background-size: 100%;
          background-clip: text;
        `
      : css`
          color: ${colors[$color]};
        `}
  display: flex;
  align-items: center;
  white-space: pre;
  align-self: flex-end;
`

const LinkDesc = styled.div`
  ${textStyles.bodySmall};
  color: ${colors.dark300};
  overflow: hidden;
  align-self: flex-start;

  width: 235px;
  max-height: 32px;
  ${media.desktop} {
    width: 16.319vw;
    max-height: 2.222vw;
  }
`
